import cx from 'classnames'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { MainLayout } from '../layouts/main'

import * as styles from './index.module.css'
import * as styleUtils from '../global/utils.module.css'

export default function IndexPage(props) {
  const { products } = props.data.postgres

  const slides = products.nodes.map((product) => {
    const { category } = product.productCategories.nodes[0]

    return {
      href: `/catalog/${category.urlSlug}/${product.urlSlug}`,
      id: product.id,
      image: { src: product.image.secure_url },
      name: product.name,
    }
  })

  return (
    <MainLayout>
      <Slideshow slides={slides} />
    </MainLayout>
  )
}

export const query = graphql`
  query {
    postgres {
      products(condition: { homepage: true }) {
        nodes {
          id
          image
          name
          urlSlug
          productCategories {
            nodes {
              category {
                name
                urlSlug
              }
            }
          }
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.shape({
    postgres: PropTypes.shape({
      products: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            image: PropTypes.any,
            name: PropTypes.string.isRequired,
            productCategories: PropTypes.shape({
              nodes: PropTypes.arrayOf(
                PropTypes.shape({
                  category: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    urlSlug: PropTypes.string.isRequired,
                  }).isRequired,
                }).isRequired,
              ),
            }).isRequired,
          }).isRequired,
        ).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

function Slideshow(props) {
  const { slides } = props

  const [auto, setAuto] = React.useState(true)
  const [activeSlide, setActiveSlide] = React.useState(0)

  const next = () => {
    if (activeSlide === slides.length - 1) setActiveSlide(0)
    else setActiveSlide(activeSlide + 1)
  }

  const forceSlide = (i) => {
    setAuto(false)
    setActiveSlide(i)
  }

  useInterval(() => {
    if (auto) next()
  }, 3000)

  return (
    <div className={styles.slideshow}>
      <div
        aria-label="feature products"
        aria-roledescription="carousel"
        className={styles.slides}
        role="presentation"
      >
        {slides.map((slide, i) => (
          <div
            aria-label={`${i + 1} of ${slides.length + 1}`}
            aria-roledescription="slide"
            role="group"
            className={cx({
              [styles.slide]: true,
              [styles.slideActive]: activeSlide === i,
            })}
            key={i}
          >
            <Link to={slide.href}>
              <img alt={slide.name} src={slide.image.src} />
            </Link>
          </div>
        ))}
      </div>

      <ul className={styles.dots} role="tablist">
        {slides.map((_, i) => (
          <li key={i} role="presentation">
            <button
              className={cx({
                [styleUtils.resetButton]: true,
                [styles.dot]: true,
                [styles.dotActive]: activeSlide === i,
              })}
              onClick={() => {
                forceSlide(i)
              }}
              role="tab"
              tabIndex={-1}
            >
              {i + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
Slideshow.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      image: PropTypes.shape({ src: PropTypes.string.isRequired }),
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

function useInterval(callback, delay) {
  const intervalId = React.useRef(null)
  const savedCallback = React.useRef(callback)

  React.useEffect(() => {
    savedCallback.current = callback
  })

  React.useEffect(() => {
    const tick = () => savedCallback.current()
    if (typeof delay === 'number') {
      intervalId.current = window.setInterval(tick, delay)
      return () => window.clearInterval(intervalId.current)
    }
  }, [delay])

  return intervalId.current
}
